@tailwind base;
@tailwind components;
@tailwind utilities;

.blinking {
  @apply hover:animation-blink;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* Add custom animation utility to Tailwind */
@layer utilities {
  .animation-blink {
    animation: blink 0.5s step-end infinite;
  }
}
/* /Button.css / */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin 2s linear infinite;
}

.link-button {
  position: relative;
  display: inline-block;
}

.link-button::before,
.link-button::after {
  content: "";
  position: absolute;
  border: 4px solid white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link-button::before {
  width: 100%;
  height: 100%;
  border-color: rgba(255, 255, 255, 0.5);
  z-index: 0;
}

.link-button::after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: rgba(255, 255, 255, 0.3);
  z-index: -1;
}
